<template>
  <el-dialog
    custom-class="select-user"
    :visible.sync="show"
    width="55.62vw"
    center
    append-to-body
    :before-close="handleClose"
  >
    <div class="header-bar" slot="title">选择团队</div>
    <div class="flex_between">
      <div class="select-cont">
        <p class="top-text">选择：</p>
        <div class="select-box search">
          <!--v-if="defaultDeptId===1"-->
          <div class="operation">
            <div class="search-input">
              <el-input
                v-model="search"
                prefix-icon="el-icon-search"
                @input="handleSearch"
                :placeholder="
                  onlyDept ? '暂不支持部门搜索' : '请输入部门关键字'
                "
                :disabled="onlyDept"
                clearable
              />
            </div>
            <div class="back-baseCompany">
              <el-button type="text" @click="handleBaseCompany"
                >回到顶部</el-button
              >
            </div>
          </div>
          <div class="nav-list">
            <span
              class="nav-item"
              :class="{ link_text: index !== navList.length - 1 || isSearch }"
              @click="handleNavClick(item, index)"
              v-for="(item, index) in navList"
              :key="index"
              >{{ item.name }}</span
            >
          </div>
          <div
            class="select-all"
            v-if="multiple"
            @click="handleSelectAllChange(selectAll)"
          >
            <span class="check-box">
              <i class="iconfont icon-xuanze2-copy" v-if="selectAll"></i>
              <i class="iconfont icon-xuanze" v-if="!selectAll"></i>
            </span>
            <span>全选</span>
          </div>
          <div class="select-option-box">
            <!-- <div v-if="department && department.length === 0">
              <span>暂无其它部门</span>
            </div> -->
            <div
              class="select-option-item flex_row"
              v-for="item in department"
              :key="item.id"
              @click="handleDeptSelect(item)"
            >
              <span class="check-box">
                <i class="iconfont icon-jinzhi" v-if="onlyUser"></i>
                <i
                  class="iconfont icon-xuanze2-copy"
                  v-else-if="selectedId.includes(item.id)"
                ></i>
                <!-- <i class='iconfont icon-xuanze' v-else></i>  -->
                <i class="iconfont icon-xuanze" v-else></i>
              </span>
              <p class="name text_overflow">{{ item.name }}</p>
              <span
                class="next-level"
                :class="{ disable: selectedId.includes(item.id) }"
                @click.stop="handleNext(item.id)"
              >
                <i class="iconfont icon-ic_b-commond_departm"></i>
                <span>下级</span>
              </span>
            </div>
            <template v-if="!onlyDept">
              <div
                class="select-option-item flex_row"
                v-for="item in person"
                :key="item.adminUserId"
                @click="handleUserSelect(item)"
              >
                <span class="check-box">
                  <i
                    class="iconfont icon-xuanze2-copy"
                    v-if="selectedId.includes(item.adminUserId)"
                  ></i>
                  <i class="iconfont icon-xuanze" v-else></i>
                </span>
                <img
                  :src="`${
                    item.avatar
                      ? item.avatar
                      : require('./../../../assets/favicon.png')
                  }`"
                  alt="avatar"
                  class="avatar"
                />
                <div>
                  <p class="name text_overflow" style="">{{ item.name }}</p>
                  <p v-if="isSearch" class="dept-name">{{ item.deptName }}</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="select-cont">
        <p class="top-text">已选：</p>
        <div class="select-box checked">
          <div
            class="selected-item flex_row"
            v-for="(item, index) in selected"
            :key="index"
          >
            <div class="name flex_row">
              <template v-if="item.type === 0">
                <i class="iconfont icon-ic_b-commond_departm"></i>
                <span style="padding-left: 7px">{{ item.name }}</span>
              </template>
              <template v-else>
                <div class="name flex_row">
                  <!-- <img :src="`${item.avatar ? item.avatar:require('./../../assets/favicon.png')}`" alt="avatar" class="avatar"> -->
                  <!-- <img src="" alt=""> -->
                  <i class="iconfont icon-ic_b-commond_departm"></i>
                  <span style="line-height: 2; padding-left: 7px">{{
                    item.name
                  }}</span>
                </div>
              </template>
            </div>
            <i
              class="iconfont icon-close"
              @click="selected.splice(index, 1)"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <span class="btn cancel" @click="handleClose">取消</span>
      <span class="btn confirm" @click="handleConfirm">确认</span>
    </span>
  </el-dialog>
</template>

<script>
import { getDeptUserOption } from "@/api/employee";
import { mapGetters } from "vuex";

let inSearch = false;

export default {
  name: "selectDeptUser",
  props: {
    show: Boolean,
    onlyDept: {
      type: Boolean,
      default: false,
    },
    onlyUser: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isNull: {
      type: Boolean,
      default: false,
    },
    defaultValue: Array,
  },
  data() {
    return {
      selectAll: false, // 是否全选
      navList: [],
      deptId: null,
      search: null,
      person: [], // 员工列表
      department: [], // 部门列表
      selected: [], // 选中列表 { type:类型，0为部门，1为用户 name,id }
      isSearch: false,
      loading: false,
      init: false,
      isFirstEnter: true, // 是否是首次进入
    };
  },
  computed: {
    selectedId() {
      return this.selected.map((item) => item.id);
    },
    ...mapGetters(["userInfo"]),
  },
  watch: {
    show(newVal) {
      if (newVal) {
        // this.deptId = this.defaultDeptId ? this.defaultDeptId : this.deptId || this.userInfo.deptId;
        this.deptId = 1;
        this.search = null;
        this.init = true;
        this.isFirstEnter = true; // !每次show为true的时候 重置 isFirstEnter为true
        // const usersinfo = this.userInfo
        // !初次map的是item.deptId deptName adminUserId 之后再选择部门之后确认之前再次编辑为了方便回显
        // ! this.selected 调用的是 item.id, item.name, adminUserId
        this.selected = this.defaultValue
          ? [...this.defaultValue].map((item) => {
              return {
                id: item.deptId ? item.deptId : item.id,
                name: item.deptName ? item.deptName : item.name,
                adminUserId: item.adminUserId ? item.adminUserId : "",
              };
            })
          : [];
        // this.selected = this.defaultValue ? [...this.defaultValue] : [];
        // ;
        // ;
        // 用一个标杆判断是否加入自己的部门
        // let pushFlag = false;

        // 以下操作是判断已选部门有没有当前用户部门，没有就加入编辑框
        // this.selected.filter(item =>{
        //   if(item.id === usersinfo.deptId){
        //       return pushFlag = true;
        //   }
        // })
        // if(!pushFlag) {
        //   this.selected.push({id:usersinfo.deptId,name:usersinfo.deptName,type:0})
        // }
        this.getList();
      }
    },
    selected: {
      handler(newV, oldV) {},
    },
  },
  methods: {
    handleNavClick(data, index) {
      this.isSearch = false;
      this.search = null;
      const { navList } = this;
      if (navList.length - 1 > index || this.isSearch) {
        this.deptId = data.id;
        this.getList();
      }
    },
    handleNext(deptId) {
      if (this.selectedId.includes(deptId)) return false;
      this.deptId = deptId;
      this.getList();
    },
    handleUserSelect(item) {
      if (this.onlyDept) return false;
      const _index = this.selectedId.findIndex((id) => id === item.userId);
      if (_index === -1) {
        const user = {
          type: 1,
          id: item.userId,
          name: item.nickname,
          avatar: item.avatar,
        };
        if (this.multiple) {
          this.selected.push(user);
        } else {
          this.selected = [user];
        }
      } else {
        this.selected.splice(_index, 1);
      }
    },
    handleDeptSelect(item) {
      if (this.onlyUser) return false;
      // const _index = this.selectedId.findIndex(id => id === item.sonId);
      const _index = this.selectedId.findIndex((id) => id === item.id);
      if (_index === -1) {
        const dept = {
          type: 0,
          id: item.id,
          name: item.name,
        };
        if (this.multiple) {
          this.selected.push(dept);
        } else {
          this.selected = [dept];
        }
      } else {
        this.selected.splice(_index, 1);
      }
    },
    getList() {
      this.loading = true;
      getDeptUserOption({ deptId: this.deptId, search: this.search })
        .then((res) => {
          this.person = res.person;
          if (this.isFirstEnter) {
            this.department = res.breadcrumb;
            this.isFirstEnter = false;
          } else {
            this.department = res.department;
          }
          this.navList = res.breadcrumb;
          // this.selectedId
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSearch() {
      if (inSearch) return false;
      // let value
      // if (this.department) {
      //   value = this.department.find(dept => {
      //     return dept.name.includes(this.search)
      //   })
      // }
      // // const deptId = value ? value.id : 1
      if (this.search) {
        this.loading = true;
        inSearch = true;
        // getDeptUserOption({ deptId: deptId, search: value ? value.name : "" })
        getDeptUserOption({ search: this.search })
          .then((res) => {
            this.person = res.person;
            this.department = res.department;
            this.isSearch = true;
            inSearch = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.getList();
      }
    },
    handleSelectAllChange(selectAll) {
      if (selectAll) {
        this.selected = [];
      } else {
        const dept = this.department.map((item) => {
          return {
            // id: item.sonId,
            id: item.id,
            name: item.name,
            type: 0,
          };
        });
        const user = this.person.map((item) => {
          return {
            type: 1,
            // id: item.userId,
            id: item.adminUserId,
            name: item.name,
            avatar: item.avatar,
          };
        });
        if (this.onlyUser) {
          this.selected = user;
        } else if (this.onlyDept) {
          this.selected = dept;
        } else {
          this.selected = dept.concat(user);
        }
      }
      this.selectAll = !selectAll;
    },
    handleClose($event, flag = false) {
      this.isSearch = false;
      this.search = null;
      this.selectAll = false;
      this.$emit("close");
    },
    handleConfirm() {
      // ;
      // if (this.selected.length < 1) {
      //   if (!this.isNull) return;
      // }
      this.$emit("confirm", this.multiple ? this.selected : this.selected[0]);

      this.handleClose();
    },
    handleBaseCompany() {
      this.deptId = 1;
      this.search = "";
      // 重置为true 返回到最顶部
      this.isFirstEnter = true;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-dialog__header {
  padding: 0;
}
// @import "../assets/scss/themeify";
.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.not-allow-select {
  color: #c9c9c9;
}

.dept-name {
  font-size: 12px;
  margin-top: 8px;
  // @include themeify {
  //   color: themed('color-text-secondary');
  // }
}
.operation{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-input {
  padding-right: 15px;
  // margin-bottom: 20px;
  width: 200px;
}

.back-baseCompany {
  padding-right:15px;
}

.select-option-box {
  flex: 1;
  overflow-y: auto;
  margin-top: 25px;
  padding-right: 15px;

  .select-option-item {
    margin-bottom: 25px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    .name {
      flex: 1;
    }

    .next-level {
      font-size: 14px;
      color: #2a9aff;

      .iconfont {
        font-size: 14px;
      }

      &.disable {
        cursor: not-allowed;
        color: #c9c9c9;
      }
    }
  }
}

.check-box {
  margin-right: 7px;

  .iconfont {
    font-size: 14px;
  }

  .selected-icon {
    color: #7498f9;
  }

  .unselect-icon {
    // @include themeify {
    //   color: themed('color-text-secondary');
    // }
  }
}

.select-all {
  margin-top: 20px;
  width: 65px;
  cursor: pointer;
  font-size: 14px;
  height: 17px;
  line-height: 17px;
}

.search {
  padding: 15px 0 15px 15px;
  display: flex;
  flex-direction: column;

  .nav-list {
    .nav-item {
      display: inline-block;
      padding: 10px 20px 0 0;
      position: relative;
      cursor: pointer;
      font-weight: bold;
      &:after {
        content: "/";
        position: absolute;
        right: 6px;
        // @include themeify {
        //   color: themed('color-text-primary');
        // }
      }
    }

    .nav-item:last-child:after {
      content: "";
    }
    .link_text {
      color: rgb(133, 133, 252);
    }
  }
}

.checked {
  padding: 20px;
  overflow-y: auto;

  .name {
    flex: 1;
    font-weight: bold;

    svg {
      margin-right: 7px;
    }
  }

  .close {
    font-size: 16px;
    cursor: pointer;
  }

  .selected-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.select-cont {
  width: 100%;
  // width: 410px;
  &:nth-of-type(1) {
    margin-right: 20px;
  }
  .avatar {
    width: 30px;
    height: 30px;
    margin-right: 7px;
    border-radius: 50%;
  }

  .top-text {
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .select-box {
    width: 100%;
    height: 390px;
    border-radius: 10px;
    background: #fafaff;
    // @include themeify {
    //   background-color: themed('body_back_color');
    // }
  }
}

.header-bar {
  height: 72px;
  line-height: 72px;
  font-size: 20px;
  font-weight: bold;
  background: #fafaff;
  // @include themeify {
  //   background-color: themed('body_back_color');
  //   color: themed("color-text-primary");
  // }
}

.dialog-footer {
  .btn {
    display: inline-block;
    width: 90px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    border-radius: 16px;
    letter-spacing: 2px;
    cursor: pointer;

    &.cancel {
      border-width: 1px;
      border-style: solid;
      margin-right: 46px;
      // @include themeify {
      //   border-color: themed('border-color');
      // }
    }

    &.confirm {
      color: #fff;
      background-color: #7498f9;
    }

    &.danger {
      color: #fc646e;
      background-color: #fc646e;
    }
  }
}
.dark .dialog-footer .btn.cancel {
  color: #fff;
}
</style>
