<template>
  <div>
    <div
      class="
        part
        border-radius-6
        padding-20
        border-radius-6
        box-shadow-light-grey
        height-calc-type1
      "
    >
      <div class="flex-between operation padding-bottom-20">
        <div class="row" style="flex: 1">
          <el-button @click="getList">刷新数据</el-button>
          <el-input
            placeholder="请输入内容"
            v-model="search"
            class="input-with-select margin-left-twentyFour"
            clearable
            @keydown.enter.native="getList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleDialogShow(null, 'isEdit')"
          >新增用户</el-button
        >
      </div>
      <el-table
        v-loading="loading"
        :data="dataList"
        height="calc(100vh - 310px)"
        style="width: 100%"
      >
        <el-table-column prop="id" label="用户ID" width="100">
        </el-table-column>
        <el-table-column prop="name" label="用户姓名">
          <template slot-scope="scope">
            <div class="row">
              <!--            <user-avatar :info="scope.row"/>-->
              <span style="margin-left: 10px">{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号"> </el-table-column>
        <el-table-column prop="isPut" label="是否为投手">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isPut === 1">是</el-tag>
            <el-tag v-if="scope.row.isPut === 0" type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isPut" label="数据权限">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.allData === 1">全部</el-tag>
            <el-tag v-if="scope.row.allData === 0" type="danger">部分</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="首页角色" prop="homeRole">
          <template slot-scope="scope">
            <p v-if="scope.row.homeRole">
              {{ scope.row.homeRole | getHomeRole }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="role" label="部门">
          <template slot-scope="scope">
            <p v-if="scope.row.deptUserRelations">
              {{
                scope.row.deptUserRelations.map((item) => item.deptName).join()
              }}
            </p>
            <p v-else>无</p>
          </template>
        </el-table-column>
        <el-table-column prop="role" label="角色">
          <template slot-scope="scope">
            <p v-if="scope.row.roleUsers">
              {{ scope.row.roleUsers.map((item) => item.roleName).join() }}
            </p>
            <p v-else>无</p>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间" width="180">
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="450px"
          fixed="right"
        >
          <template slot-scope="scope">
            <!-- <el-button class='button-small' size='small' type='info' @click='showOcean(scope.row)'>关联巨量引擎</el-button> -->
            <el-button
              class="button-small"
              size="small"
              type="warning"
              @click="handleDialogShow(scope.row, 'isFieldManager')"
            >
              字段权限
            </el-button>
            <el-button
              class="button-small"
              size="small"
              @click="handleDialogShow(scope.row, 'isSetStatistic')"
              >关联投放</el-button
            >
            <el-button
              class="button-small"
              type="primary"
              size="small"
              @click="handleDialogShow(scope.row, 'isEdit')"
              >编辑</el-button
            >
            <el-button
              class="button-small"
              type="danger"
              size="small"
              @click="deleteItem(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
      :visible.sync="isEdit"
      :title="`${current ? '编辑' : '新增用户'}`"
      direction="rtl"
      size="500px"
    >
      <edit
        :show="isEdit"
        :info="current"
        :deptInfoList="deptList"
        @close="isEdit = false"
        @refresh="getList"
      />
    </el-drawer>
    <set-account
      :show="isSetAccount"
      :user="current"
      @close="isSetAccount = false"
    />
    <set-ocean :show="isSetOcean" :user="current" @close="isSetOcean = false" />
    <el-drawer
      :visible.sync="isSetStatistic"
      direction="rtl"
      :title="statisticName"
      size="1200px"
      :append-to-body="true"
    >
      <statistic
        :show="isSetStatistic"
        :user="current"
        @close="isSetStatistic = false"
      />
    </el-drawer>
    <el-drawer
      :title="`${current ? current.name : ''} 字段权限`"
      size="600px"
      :visible.sync="isFieldManager"
      :append-to-body="true"
      :before-close="beforeClose"
    >
      <field-manager
        ref="fieldManager"
        :current="current"
        :show="isFieldManager"
        @close="isFieldManager = false"
      ></field-manager>
    </el-drawer>
    <transition name="fade">
      <permissions
        class="permissionscp"
        v-if="isPermissions"
        :user="current"
        @close="isPermissions = false"
      />
    </transition>
    <div
      class="permissionsscreen"
      v-if="isPermissions"
      @click="isPermissions = false"
    ></div>
  </div>
</template>

<script>
import { getUserList, deleteUser } from "@/api/user";
import Edit from "./edit";
import SetAccount from "./account";
import SetOcean from "./oceanAccount";
import Statistic from "./statistic";
import permissions from "./permissions";
import fieldManager from "./fieldManager";

export default {
  name: "User",
  data() {
    return {
      search: null,
      loading: false,
      page: 1,
      total: 0,
      dataList: [],
      isEdit: false,
      current: {},
      isSetAccount: false,
      isSetOcean: false,
      isSetStatistic: false,
      isPermissions: false,
      isFieldManager: false,
      statisticName: "", // 关联投放用户姓名
      deptList: [],
      homeRole: "",
    };
  },
  filters: {
    getHomeRole(val) {
      const homeRoleList = [
        "全部",
        "投手驾驶舱",
        "运营决策驾驶舱",
        "投放决策驾驶舱",
      ];

      return val
        .split(",")
        .map((item) => {
          return homeRoleList[Number(item)] || "";
        })
        .join(",");
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.getList());
  },
  methods: {
    handleRowClick(command, data) {
      switch (command) {
        case "edit":
          this.handleDialogShow(data, "isEdit");
          break;
        case "delete":
          this.deleteItem(data);
          break;
      }
    },
    handleDialogShow(data, props) {
      this.current = data;
      if (!data) {
        this.deptList = [];
      }
      if (data) {
        const { name } = this.current;
        this.statisticName = `${name}关联投放`;
        if (props === "isEdit") {
          if ("deptUserRelations" in data) {
            this.deptList = data.deptUserRelations;
          } else {
            this.deptList = [];
          }
        }
      }
      this[props] = true;
    },
    showOcean(row) {
      this.current = row;
      this.isSetOcean = true;
    },
    deleteItem(data) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteUser(data.id).then(() => {
            this.handlePageChange(1);
          });
        })
        .catch(() => {});
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      getUserList({
        page: this.page,
        pageSize: 15,
        name: this.search,
        homeRole: this.homeRole,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    beforeClose() {
      this.$refs.fieldManager.handleClose();
    },
  },
  components: {
    Edit,
    SetAccount,
    SetOcean,
    Statistic,
    permissions,
    fieldManager,
  },
};
</script>

<style lang="scss" scoped>
.input-with-select {
  width: 300px;
}
.fade-enter {
  right: -400px;
}
.fade-enter-active {
  transition: right 0.3s;
}
.fade-leave-to {
  right: -400px;
}
.fade-leave-active {
  transition: right 0.3s;
}
.permissionsscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.operation {
  background: #fff;
  // padding: 15px;
  // margin-top: 20px;
}
.part {
  margin-top: 16px;
  box-sizing: border-box;
  background: #fff;
}
/deep/ .el-drawer {
  overflow-y: auto;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
