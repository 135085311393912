<template>
  <div class="field-manager" v-loading="loading">
    <div class="field-manager-container">
      <div class="field-search">
        <el-input
          v-model="search"
          placeholder="请输入类型名称"
          @input="handleInput"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
        <div>
          <el-button type="primary" @click='checkAll'>全选</el-button>
          <el-button @click="handleChangeCollapse"
            >{{ isCollapse ? "收起" : "展开" }}&nbsp;<i
              :class="isCollapse ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
            ></i
          ></el-button>
        </div>
      </div>
      <div class="field-list">
        <div
          class="field-item"
          v-for="(item, index) in fieldList"
          :key="item.id"
        >
          <div class="name-and-operation">
            <span>{{ item.typeName }}</span>
            <i
              @click="handleItemClick(item)"
              :class="[
                item.unfold ? 'el-icon-arrow-right' : 'el-icon-arrow-down'
              ]"
            ></i>
          </div>
          <el-collapse-transition>
            <div class="sub-field" v-show="item.unfold">
              <!-- <div class='sub-field-item' v-for="field in item.fieldList" :key="field.id">
              <p class="sub-fieldName-and-checkedStatus">
                <span>{{ field.fieldName }}</span>
                <i @click='handleFieldName(field)' class='iconfont ' :class="[field.checkStatus ? 'icon-xuanze' : 'icon-xuanze']" ></i>
              </p>
            </div> -->
              <el-checkbox
                v-model="All[index]"
                @change="handleCheckAllChange($event, item, index)"
                >全选</el-checkbox
              >
              <div style="margin: 15px 0;"></div>
              <el-checkbox-group
                v-model="checkedField"
              >
                <el-checkbox
                  v-for="field in item.fieldList"
                  :label="field.id"
                  :key="field.id"
                  >
                  <span>{{ field.fieldName }}</span>
                  </el-checkbox
                >
              </el-checkbox-group>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>
    <div class="button-list">
      <el-button  @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleConfirm">保存</el-button>
    </div>
  </div>
</template>
<script>
import {
  getAllRelation,
  getUserRelation,
  editUserRelation
} from '@/api/field'
import { flatDeep } from '@/assets/js/utils'
export default {
  name: 'fieldManager',
  props: ['current', 'show'],
  data () {
    return {
      targetIndex: 0,
      search: '',
      fieldList: [],
      copyFieldList: [], // 保存一份fieldList的副本
      loading: true,
      isIndeterminate: false,
      checkedField: [],
      copyCheckedField: [], // 保存一份checkedField的副本
      diviIds: [], // 每个模块区间权限id合集 一个二维数组,
      isCollapse: true,
      allStatus: false, // 最外层的全选标识
      All: [] // 分别给每个字段类型设置全选标识
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.initData()
        } else {
          this.resetData()
        }
      },
      immediate: true
    },
    checkedField () {}
  },
  methods: {
    initData () {
      getAllRelation().then(res => {
        this.All = Array.from({ length: res.length }, () => false)
        res = res.map(item => {
          this.diviIds.push([...item.fieldList.map(field => field.id)])
          return {
            ...item,
            unfold: true // 是否展开
          }
        })
        if (this.current) {
          const { id } = this.current
          getUserRelation(id).then(response => {
            const checkedFiledItemIds = response
            this.checkedField = response
            this.copyCheckedField = response

            res.forEach(field => {
              field.fieldList.forEach(item => {
                if (checkedFiledItemIds.includes(item.id)) {
                  // !如果该用户有该字段权限 则设置为true
                  item.checkStatus = true
                }
              })
            })
            this.fieldList = res
            this.copyFieldList = res
            this.loading = false
          })
        }
      })
    },

    changeAllCheckStyle (flag, index) {
      this.All.forEach((item, i) => {
        item = i === index
      })
    },
    handleCheckAllChange (e, val, index) {
      const targetArrIds = this.diviIds.find((item, i) => i === index)
      this.changeAllCheckStyle(e, index)
      if (e) {
        this.checkedField = [
          ...new Set([...this.checkedField, ...targetArrIds])
        ]
      } else {
        targetArrIds.forEach(id => {
          const key = this.checkedField.findIndex(item => item === id)
          if (key > -1) {
            this.checkedField.splice(key, 1)
          }
        })
      }
    },
    handleItemClick (item) {
      item.unfold = !item.unfold
    },
    handleFieldName (field) {
      field.checkStatus = !field.checkStatus
    },
    handleInput (val) {
      this.search = val
    },
    handleSearch () {
      this.loading = true
      this.fieldList = this.copyFieldList.filter(item => {
        return item.typeName.includes(this.search)
      })
      this.loading = false
    },
    handleClose () {
      // !重置之前的值
      this.checkedField = [...this.copyCheckedField]
      this.loading = true
      this.$emit('close')
    },
    handleChangeCollapse () {
      this.isCollapse = !this.isCollapse
      this.fieldList.forEach(item => {
        item.unfold = !!this.isCollapse
      })
    },
    handleConfirm () {
      const { id } = this.current
      editUserRelation(id, this.checkedField)
        .then(() => {
          this.$message({
            showClose: true,
            message: '修改成功!',
            type: 'success'
          })
        })
        .finally(() => {
          this.handleClose()
          this.resetData()
        })
    },
    checkAll () {
      this.allStatus = !this.allStatus
      if (this.allStatus) {
        this.checkedField = flatDeep(this.diviIds)
      } else {
        this.checkedField = []
      }
      if (this.allStatus) {
        this.All = this.All.map(item => (item = true))
      } else {
        this.All = this.All.map(item => (item = false))
      }
    },
    resetData () {
      this.diviIds = []
      this.checkedField = []
      this.fieldList = []
      this.isCollapse = true
    }
  }
}
</script>
<style lang="scss" scoped>
.el-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .el-checkbox {
    width: 20%;
    margin-right:10px;
  }
}
/deep/.el-checkbox__inner{
  width:10px;
  height:10px;

}
/deep/ .el-checkbox__inner::after{
  height:4px;
  left:2px;
}
/deep/.el-checkbox__label{
  font-size:12px;
}
.el-input {
  width: 300px;
}
.field-manager {
  .field-manager-container {
    .field-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 20px 20px 20px;
    }
    .field-list {
      .field-item {
        .name-and-operation {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
          margin: 0 20px;
          border-top: 1px solid #ebeef5;
          color: #72767b;
          font-weight: bold;
          span {
            font-size: 18px;
          }
        }
        .sub-field {
          padding: 20px 40px;
          .sub-field-item {
            padding: 10px;
            .sub-fieldName-and-checkedStatus {

              i {
                font-size: 18px;
                color: #72767b;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .button-list {
    // position: fixed;
    // bottom: 0%;
    // left: 85%;

    z-index: 999;
    text-align: center;
    padding: 10px;
    background: #fff;
  }
}
</style>
