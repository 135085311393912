import request from './config'

export function getRoleList () {
  return request.get('/role')
}

export function editRole ({ id, name }) {
  if (id) {
    return request.put(`/role/${id}`, { name })
  } else {
    return request.post('/role', { name })
  }
}

export function deleteRole (id) {
  return request.delete(`/role/${id}`)
}

export function getRoleUser (id, params) {
  return request.get(`/role/user/${id}`, { params })
}

export function setRoleUser (roleId, adminUserIds) {
  return request.post('/role/user', { roleId, adminUserIds })
}

export function deleteRowUser (ids) {
  return request.delete(`/role/user/${ids}`)
}

export function moveUserRole (id, roleId) {
  return request.put(`/role/user/move/${id}?roleId=${roleId}`)
}

export function getRoleAuth (roleId) {
  return request.get(`/role/permission/${roleId}`)
}

export function setRoleAuth (roleId, permissionIds) {
  return request.put(`/role/permission/${roleId}`, { permissionIds })
}
