<template>
  <div class="permissionswp">
    <div class="contentwp">
      <div class="every" v-for="(item, index) in datalist" :key="index">
        <div class="title" v-text="item.typeName"></div>
        <el-checkbox-group class="checkboxGroup" v-model="checkList">
          <el-checkbox
            :label="item1.fieldId"
            v-for="item1 in item.list"
            :key="item1.id"
            >{{ item1.fieldName }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <div class="btnwp">
      <el-button type="info" size="medium" @click="cancel">取消</el-button>
      <el-button type="primary" size="medium" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  adduserRelation,
  searchuserRelation,
  alluserRelation,
} from "@/api/permissions";
export default {
  name: "permissions",
  props: {
    user: Object,
  },
  data() {
    return {
      activeNames: ["0"],
      datalist: [],
      checkList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      const that = this;
      axios.all([alluserRelation(), searchuserRelation(this.user.id)]).then(
        axios.spread(function (acct, perms) {
          that.datalist = acct;
          that.checkList = perms;
          // arr.forEach(function(val, index, arr){
          // });
        })
      );
    },
    cancel() {
      this.$emit("close");
    },
    save() {
      const fieldIdarr = this.checkList;
      adduserRelation(this.user.id, fieldIdarr).then((res) => {
        if (JSON.stringify(res) === "{}") {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.cancel();
        }
      });
    },
  },
};
</script>

<style scoped>
.permissionswp {
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  z-index: 101;
  overflow: hidden;
}
.contentwp {
  width: 100%;
  box-sizing: border-box;
  padding: 50px;
  height: calc(100% - 100px);
  background-color: #fff;
  overflow: hidden;
  overflow-y: scroll;
}
.title {
  width: 100%;
  line-height: 50px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
}
.btnwp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 101;
}
.every {
  margin-bottom: 30px;
}
.checkboxGroup {
  margin-top: 20px;
  margin-left: 30px;
}
</style>
