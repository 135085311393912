<template>
  <div class="statistic-container">
    <!-- <div>
      <div class="header">{{ user ? user.name :""}}关联投放</div>
    </div> -->
    <div class="container">
      <div class="flex-between">
        <el-button
          :disabled="selectAccount.length === 0"
          type="primary"
          @click="isSelectTime = true"
          >批量设置日期</el-button
        >
        <el-button type="primary" icon="el-icon-plus" @click="dialogShow = true"
          >添加关联账号</el-button
        >
      </div>
      <el-table
        v-loading="loading"
        border
        height="600px"
        :data="selectItems"
        @selection-change="handleSelectionChange"
        style="width: 100%; margin-top: 15px"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="accountChannelId"
          align="center"
          width="80px"
          label="公众号ID"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="100px"
          prop="channelName"
          label="公众号名称"
        >
        </el-table-column>
        <el-table-column
          prop="bindStartDate"
          align="center"
          label="统计开始日期"
          width="180px"
        >
          <template slot-scope="scope">
            <el-date-picker
              clearable
              style="width: 150px"
              value-format="yyyy-MM-dd"
              v-model="scope.row.bindStartDate"
              type="date"
              :picker-options="pickOptions"
              placeholder="选择日期"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column
          prop="bindEndDate"
          align="center"
          width="180px"
          label="统计结束日期"
        >
          <template slot-scope="scope">
            <el-date-picker
              clearable
              style="width: 150px"
              value-format="yyyy-MM-dd"
              v-model="scope.row.bindEndDate"
              type="date"
              :picker-options="endPickOption"
              placeholder="选择日期"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="是否为测书号" width="170px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isTest"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
            <el-input
              style="width: 100px; margin-left: 10px"
              v-if="scope.row.isTest === 1"
              v-model="scope.row.totalCostAmount"
              type="number"
              placeholder="总消耗金额,0为不限制"
            />
          </template>
        </el-table-column>
        <!-- 新增是否智能 -->
        <el-table-column label="是否智能" width="100px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isAuto"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!-- 新增自动结束时间 -->
        <el-table-column
          prop="autoEndDate"
          align="center"
          width="180px"
          label="结束日期"
        >
          <template slot-scope="scope">
            <el-date-picker
              clearable
              style="width: 150px"
              value-format="yyyy-MM-dd"
              v-model="scope.row.autoEndDate"
              type="date"
              :picker-options="endPickOption"
              placeholder="选择日期"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="60px">
          <template slot-scope="scope">
            <el-button
              type="danger"
              @click="selectItems.splice(scope.$index, 1)"
              icon="el-icon-delete"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="warning" @click="handleReset">清除设置</el-button>
      <el-button @click="selectItems = []" type="danger">清除选择</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitLoading"
        >确 定</el-button
      >
    </span>
    <el-drawer
      title="选择关联公众号"
      :visible.sync="dialogShow"
      size="500px"
      :append-to-body="true"
    >
      <!-- <el-dialog
      title="选择关联公众号"
      :visible.sync="dialogShow"
      width="500px"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="
        () => {
          this.dialogShow = false;
          this.selectOfficial = [];
        }
      "
    > -->
      <el-form label-width="80px" style="height: 200px">
        <el-form-item>
          <el-radio v-model="checkAll" :label="1" border size="medium"
            >全部账号</el-radio
          >
          <el-radio v-model="checkAll" :label="0" border size="medium"
            >部分账号</el-radio
          >
        </el-form-item>
        <el-form-item label="公众号" v-if="checkAll === 0">
          <el-select
            v-model="selectOfficial"
            placeholder="请选择"
            clearable
            multiple
            value-key="id"
            style="width: 100%"
            filterable
          >
            <el-option
              v-for="item in officialList"
              :key="item.id"
              :label="item.channelName"
              :value="item"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="button-operation">
        <el-button
          @click="
            dialogShow = false;
            selectOfficial = [];
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="handleSelectSubmit">确 定</el-button>
      </div>
    </el-drawer>
    <el-dialog
      title="选择统计开始日期"
      :visible.sync="isSelectTime"
      width="500px"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="() => (this.isSelectTime = false)"
    >
      <el-form>
        <el-form-item label="设置时间">
          <el-radio v-model="setTimeType" :label="0" border size="medium"
            >开始日期</el-radio
          >
          <el-radio v-model="setTimeType" :label="1" border size="medium"
            >结束日期</el-radio
          >
        </el-form-item>
        <el-form-item label="统计日期">
          <el-date-picker
            v-model="selectTime"
            value-format="yyyy-MM-dd"
            type="date"
            :picker-options="setTimeType === 0 ? pickOptions : endPickOption"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span class="dialog-footer">
        <el-button @click="isSelectTime = false">取 消</el-button>
        <el-button type="primary" @click="handleSelectTime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { setUserAccount, getUserAccount } from "@/api/account";
import { mapGetters } from "vuex";

export default {
  name: "statistic",
  props: {
    show: Boolean,
    user: Object,
  },
  data() {
    return {
      loading: false,
      selectAccount: [], // 选中公众号
      selectItems: [], // 选择公众号
      checkAll: 0,
      dialogShow: false,
      selectOfficial: [],
      selectTime: null,
      setTimeType: 0,
      isSelectTime: false,
      submitLoading: false,
      pickOptions: {
        disabledDate(time) {
          return (
            time < new Date("2020-01-01 00:00:00").getTime() ||
            time > Date.now()
          );
        },
      },
      endPickOption: {
        disabledDate(time) {
          return time < new Date("2020-01-01 00:00:00").getTime();
        },
      },
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.loading = true;
          getUserAccount(this.user.id, 2)
            .then((res) => {
              this.checkAll = res.type === 0 ? 1 : 0;
              this.selectItems = res.list.map((item) => {
                return {
                  accountChannelId: item.accountChannelId,
                  channelName: item.channelName,
                  bindStartDate: item.bindStartDate,
                  bindEndDate: item.bindEndDate,
                  isTest: item.isTest,
                  totalCostAmount: item.totalCostAmount,
                  isAuto: item.isAuto,
                  autoEndDate: item.autoEndDate,
                };
              });
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  methods: {
    handleReset() {
      this.selectItems.forEach((item) => {
        item.bindStartDate = null;
        item.bindEndDate = null;
        item.isTest = 0;
        item.isAuto = 0;
        item.autoEndDate = null;
        item.totalCostAmount = null;
      });
    },
    handleSubmit() {
      const { selectItems } = this;
      if (selectItems.length === 0) {
        this.$message.error("请选择关联公众号");
        return false;
      }
      const list = [];
      let hasDateEmpty = false;
      let hasAmountEmpty = false;
      for (const item of selectItems) {
        if (item.bindStartDate) {
          const temp = {
            accountChannelId: item.accountChannelId,
            bindStartDate: item.bindStartDate,
            bindEndDate: item.bindEndDate,
            adminUserId: this.user.id,
            isTest: item.isTest,
            isAuto: item.isAuto,
            autoEndDate: item.autoEndDate,
          };
          if (item.isTest === 1) {
            if (
              item.totalCostAmount ||
              typeof item.totalCostAmount === "number"
            ) {
              temp.totalCostAmount = Number(item.totalCostAmount);
            } else {
              hasAmountEmpty = true;
              break;
            }
          }
          list.push(temp);
        } else {
          hasDateEmpty = true;
          break;
        }
      }
      if (hasDateEmpty) {
        this.$message.warning("请为每个公众号选择对应统计开始时间");
        return false;
      }
      if (hasAmountEmpty) {
        this.$message.warning("请为测试号输入对应总消耗金额");
        return false;
      }
      // return;
      this.submitLoading = true;
      setUserAccount({
        accountType: 2,
        relations: list,
      })
        .then(() => {
          this.$message.success("设置成功");
          this.handleClose();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    handleSelectTime() {
      this.isSelectTime = false;
      this.loading = true;
      const { selectTime, selectItems, selectAccount, setTimeType } = this;
      this.selectItems = selectItems.map((item) => {
        const account = {
          accountChannelId: item.accountChannelId,
          channelName: item.channelName,
          isTest: item.isTest,
          totalCostAmount: item.totalCostAmount,
        };
        if (setTimeType === 0) {
          account.bindEndDate = item.bindEndDate;
          account.bindStartDate = selectAccount.includes(item.accountChannelId)
            ? selectTime
            : item.bindStartDate;
        } else {
          account.bindStartDate = item.bindStartDate;
          account.bindEndDate = selectAccount.includes(item.accountChannelId)
            ? selectTime
            : item.bindEndDate;
        }
        return account;
      });
      this.loading = false;
      this.setTimeType = 0;
      this.selectTime = null;
    },
    handleSelectSubmit() {
      this.dialogShow = false;
      this.loading = true;
      this.selectItems = this.selectItems.concat(
        this.checkAll === 0
          ? this.selectOfficial.map((item) => {
              return {
                accountChannelId: item.id,
                channelName: item.channelName,
                bindStartDate: null,
                bindEndDate: null,
                isTest: 0,
              };
            })
          : this.officialList.map((item) => {
              return {
                accountChannelId: item.id,
                channelName: item.channelName,
                bindStartDate: null,
                bindEndDate: null,
                isTest: 0,
              };
            })
      );
      this.loading = false;
      this.selectOfficial = [];
      this.checkAll = 0;
    },
    handleSelectionChange(row) {
      this.selectAccount = row.map((item) => item.accountChannelId);
    },
    handleClose() {
      this.$emit("close");
      this.selectItems = [];
      this.checkAll = 0;
    },
  },
};
</script>

<style lang="scss" scoped>

/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.statistic-container {
  .container {
    padding: 20px;
  }
}
.header {
  padding: 15px 35px;
  font-size: 20px;
  width: 100%;
  background: #eceff4;
  text-align: center;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.button-operation{
  margin-top:180px;
  text-align: center;
}
.container {
  padding-top: 20px;
}
</style>
