<template>
  <el-dialog
      :visible.sync="show"
      width="650px"
      center
      :before-close="resetData"
      :close-on-click-modal=false
      :append-to-body="true"
      :destroy-on-close="true"
      :show-close=false>
    <div slot="title">
      <div class="header">用户关联公众号</div>
    </div>
    <div class="container">
      <div class="select-cont" style="margin-right: 20px">
        <div class="title">选择：<el-checkbox v-model="checkAll">全部公众号</el-checkbox></div>
        <div class="content">
          <div style="padding: 10px;border-bottom: 1px solid #ececec">
            <div class="flex-between" style="margin-bottom: 10px">
             <el-select  clearable v-model="platform" style="width: 100px" @change="searchAccount">
                <el-option
                    v-for="(item,index) in platformList"
                    :label="item"
                    :value="index"
                    :key="index">
                </el-option>
             </el-select>
             <el-input placeholder="公众号名称" v-model="search" style="width: 150px" @keydown.enter.native="searchAccount">
                <el-button slot="append" icon="el-icon-search" @click="searchAccount"></el-button>
              </el-input>
          </div>
          <el-checkbox v-model="selectAll" @change="handleSelectAllClick">全选</el-checkbox>
          </div>
          <div class="infinite-list"
               v-if="show"
               v-infinite-scroll="getList"
               :infinite-scroll-immediate="false"
               style="overflow:auto">
            <div v-for="(item,index) in dataList" :key="index" class="infinite-item" @click="handleAccountClick(item)">
              <el-checkbox
                  :disabled="checkAll"
                  :value="selectItems.some(select=>select.id===item.id)"
                  @change="handleAccountClick(item)"></el-checkbox>
              <span class="account-name">{{ item.channelName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="select-cont">
        <div class="title">已选：</div>
        <div class="content" style="padding: 15px 0 15px 15px">
          <template>
            <el-tag v-if="checkAll" type="primary">全部公众号</el-tag>
            <el-tag
                v-else
                v-for="item in selectItems"
                :key="item.id"
                style="margin-bottom: 15px;margin-right: 15px"
            >{{item.channelName}}</el-tag>
          </template>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
            <el-button @click="resetData">取 消</el-button>
            <el-button @click="selectItems=[];checkAll=false" type="danger">清除选择</el-button>
            <el-button type="primary" @click="handleSubmit">确 定</el-button>
          </span>
  </el-dialog>
</template>

<script>
import { getAllAccount, getUserAccount, setUserAccount } from '@/api/account'
import { platformList } from '@/assets/js/options'

let isRequest = false
export default {
  name: 'SelectMember',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    user: Object
  },
  data () {
    return {
      platform: null,
      search: null,
      dataList: [],
      checkAll: false, // 是否选择全部公众号
      selectAll: false,
      selectItems: [], // 选中项
      page: 1,
      hasMore: true,
      platformList
    }
  },
  watch: {
    show (newVal) {
      if (newVal) {
        this.selectItems = []
        getUserAccount(this.user.id, 0).then(res => {
          this.selectItems = res.list
          this.checkAll = res.type === 0
        })
      }
    }
  },
  methods: {
    handleSelectAllClick (val) {
      this.selectItems = val ? this.dataList : []
    },
    handleAccountClick (data) {
      if (this.checkAll) return
      const index = this.selectItems.findIndex(item => item.id === data.id)
      if (index === -1) {
        this.selectItems.push(data)
      } else {
        this.selectItems.splice(index, 1)
      }
    },
    searchAccount () {
      this.page = 1
      this.hasMore = true
      this.dataList = []
      this.getList()
    },
    getList () {
      if (!this.hasMore || isRequest) return
      isRequest = true
      getAllAccount({
        page: this.page,
        pageSize: 20,
        channelName: this.search,
        platform: this.platform,
        accountType: 0
      }).then(res => {
        const { list, hasNextPage } = res
        this.dataList = this.dataList.concat(list)
        this.hasMore = hasNextPage
        if (hasNextPage) this.page++
      }).finally(() => { isRequest = false })
    },
    handleSubmit () {
      let accountChannelIds = null
      if (this.checkAll) {
        accountChannelIds = [0]
      } else {
        const selectId = this.selectItems.map(item => item.id)
        if (selectId.length === 0) {
          this.$message.warning('请选择关联公众号！')
          return false
        }
        accountChannelIds = selectId
      }
      setUserAccount({
        adminUserId: this.user.id,
        accountType: 0,
        accountChannelIds: accountChannelIds,
        type: this.checkAll ? 0 : 1
      }).then(() => {
        this.$message.success('设置成功')
        this.resetData()
      })
    },
    resetData () {
      this.page = 1
      this.$emit('close')
      this.platform = null
      this.search = null
      this.selectItems = []
      this.checkAll = false
    }
  }

}
</script>

<style scoped>
  .infinite-list{
    height: 380px;
  }
  .infinite-item{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 30px;
    padding: 0 20px;
    cursor: pointer;
  }
  .account-name{
    flex: 1;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 35px;
    font-size: 20px;
    width: 100%;
    background: #eceff4;
    text-align: center;
  }

  .container {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .select-cont {
    width: 300px;
    height: 460px;
  }

  .title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .content {
    width: 100%;
    border: 1px solid #dedede;
    border-radius: 4px;
    background: #f4f6f8;
    height: 465px;
    overflow-y: auto;
  }
</style>
