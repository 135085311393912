<template>
  <!-- <el-dialog
      :title="`${form.id ? '编辑':'新增'}用户`"
      :visible.sync="show"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :before-close="()=>this.$emit('close')"
      width="450px"> -->
  <div class="edit-user">
    <el-form :model="form" label-width="85px" :rules="rules">
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="form.mobile"></el-input>
        <p class="tips">请与飞书手机号保持一致</p>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="身份" prop="isPut">
        <el-select v-model="form.isPut" clearable>
          <el-option :value="0" label="运营">运营</el-option>
          <el-option :value="1" label="投手">投手</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据权限" prop="allData">
        <el-radio-group v-model="form.allData">
          <el-radio :label="0">部分</el-radio>
          <el-radio :label="1">全部</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="团队" prop="deptIds">
        <!-- <el-cascader
          style="width: 100%"
          :props="treeProps"
          v-model="form.deptIds"
          :options="deptList"
        ></el-cascader> -->
        <!-- <div style="display:flex;justify-content:flex-start;align-items:center">
          <el-input v-model="deptInfo" @focus='handleSelectDepart'></el-input>
        </div> -->
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <el-input
            type="textarea"
            v-model="form.deptIds"
            @focus="handleSelectDepart"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="角色" prop="roleIds">
        <el-select
          v-model="form.roleIds"
          style="width: 100%"
          placeholder="请选择"
          multiple
        >
          <el-option
            v-for="item in roleList"
            :key="item.name"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="首页角色">
        <el-select
          v-model="form.homeRole"
          style="width: 100%"
          placeholder="请选择首页角色"
          multiple
        >
          <el-option
            v-for="item in homeRoleList"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitLoading"
        >确 定</el-button
      >
    </div>
    <select-dept-user
      :show="isShowDeptart"
      :defaultValue="copyDeptInfoList"
      :onlyDept="true"
      :multiple="true"
      @close="handleDeptInfoList"
      @confirm="confirm"
    ></select-dept-user>
  </div>
</template>

<script>
import { editUser } from "@/api/user";
import { getRoleList } from "@/api/role";
import { getAllTeam } from "@/api/team";
import SelectDeptUser from "./selectDeptUser";

// * 校验手机格式
const checkMobileFormate = (rule, value, callback) => {
  const regExp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
  if (value === "") {
    callback('请输入手机号！')
  } else if (!regExp.test(value)) {
    callback('请输入正确格式的手机号！')
  }
};

export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    deptInfoList: Array,
    info: Object,
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          if (this.info) {
            const {
              name,
              mobile,
              id,
              isPut,
              roleUsers,
              deptUserRelations,
              allData,
              homeRole,
            } = this.info;
            this.form = {
              name,
              mobile,
              id,
              isPut,
              allData,
              homeRole: homeRole
                ? [...homeRole.split(",").map((item) => Number(item))]
                : [],
              roleIds: roleUsers ? roleUsers.map((item) => item.roleId) : [],
              // 修改为 deptName
              deptIds: deptUserRelations
                ? deptUserRelations.map((item) => item.deptName).join("，")
                : "",
            };
            this.deptSelectedIds = deptUserRelations ? deptUserRelations.map(item=>item.deptId) : [];
            // !通过show的改变重新赋值 防止查看不同账号回显的内容重复
            this.copyDeptInfoList = deptUserRelations
              ? [...deptUserRelations]
              : [];
          } else {
            this.form = {
              allData: 0,
            };
            // !新增用户将选择部门的回显内容重置
            this.copyDeptInfoList = [];
          }
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      form: {},
      submitLoading: false,
      roleList: [],
      deptList: [],
      isShowDeptart: false,
      defaultValue: [],
      treeProps: {
        multiple: true,
        checkStrictly: true,
        emitPath: false,
        value: "id",
        label: "name",
      },
      deptInfo: "",
      copyDeptInfoList: JSON.parse(JSON.stringify(this.deptInfoList)),
      deptSelectedIds: [], // !已经选择的部门id集合 调用接口时使用
      rules: {
        mobile: [
          {
            required: true,
            validator: checkMobileFormate,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名!",
            trigger: "blur",
          },
        ],

        roleIds: [
          {
            required: true,
            message: "请选择角色!",
            trigger: "blur",
          },
        ],
      },
      homeRoleList: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "投手驾驶舱",
        },
        {
          id: 2,
          name: "运营决策驾驶舱",
        },
        {
          id: 3,
          name: "投放决策驾驶舱",
        },
      ],
    };
  },
  mounted() {
    getRoleList().then((res) => {
      this.roleList = res;
    });
    getAllTeam().then((res) => {
      this.deptList = res;
    });
  },
  methods: {
    handleSelectDepart() {
      this.isShowDeptart = true;
    },
    handleDeptInfoList() {
      this.isShowDeptart = false;
    },
    checkMobileFormate() {},
    handleSelected(selected) {
      if (Object.prototype.toString.call(selected) === "[object Object]") {
        this.form.deptIds = selected.name;
        this.deptSelectedIds = [selected.id];
      }
      if (Object.prototype.toString.call(selected) === "[object Array]") {
        if (selected.length === 0) {
          this.deptSelectedIds = [];
          this.form.deptIds = "";
          return;
        }
        this.form.deptIds = selected.map((item) => item.name).join("，");
        this.deptSelectedIds = selected.map((item) => item.id);
      }
      if (Object.prototype.toString.call(selected) === "[object Undefined") {
        this.deptSelectedIds = [];
        this.form.deptIds = "";
      }
    },
    confirm(selected) {
      // 如果团队选择的团队为空

      if (!selected) {
        this.copyDeptInfoList = [];
      } else {
        // ! 方便选择部门之后查看回显
        this.copyDeptInfoList = JSON.parse(JSON.stringify(selected));
      }
      this.handleSelected(selected);
    },
    handleSubmit() {
      let { name, mobile, roleIds, isPut, allData, id, homeRole } = this.form;
      if (!mobile) {
        this.$message.warning("请输入手机号！");
        return false;
      }
      if (!name) {
        this.$message.warning("请输入姓名！");
        return false;
      }
      // if (deptIds && deptIds.length === 0) {
      //   this.$message.warning("请选择团队！");
      //   return false;
      // }
      // !防止roleIds重复的问题
      roleIds = [...new Set(roleIds)];
      if (roleIds && roleIds.length === 0) {
        this.$message.warning("请选择角色！");
        return false;
      }
      this.submitLoading = true;
      const params = {
        id,
        mobile,
        name,
        roleIds,
        isPut,
        allData,
        deptIds: this.deptSelectedIds,
        homeRole: homeRole.join(","),
      };
      editUser(params)
        .then(() => {
          this.$message.success("保存成功");
          this.$emit("refresh");
          this.$emit("close");
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
  components: {
    SelectDeptUser,
  },
};
</script>

<style lang="scss" scoped>
.tips {
  font-size: 14px;
  color: #acacac;
}
.edit-user {
  width: 450px;
  margin: 0 auto;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
